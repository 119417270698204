<template>
  <base-view-layout>
    <template #breadcrumb>
      <li class="breadcrumb__nav-item">{{$t('questionsbank.breadcrumb')}}</li>
    </template>

    <template #title>{{$t('questionsbank.title')}}</template>
    <template #subtitle>{{$t('questionsbank.subtitle')}}</template>

    <router-link :to="{ name: 'QuestionNew' }" class="btn btn--primary btn--circle btn--fixed">
      <img src="@/assets/images/icons/ico-add--white.svg">
    </router-link>
    
    <section class="main-section">
      <div class="box box--sm box--no-shadow">
        <div class="question-list question-list--sm" v-for="question in questions" :key="question.id">
          <question-card :question="question"/>
          <div class="question-add">
            <button class="btn btn--danger btn--outline btn--xs" type="button" @click.prevent="confirmDeleteQuestionById = question.id" v-if="isAdmin">{{$t('questionsbank.btndelete')}}</button>
          </div>
        </div>
      </div>
    </section>
  </base-view-layout>

  <modal v-if="confirmDeleteQuestionById" @close="confirmDeleteQuestionById = null">
    <template #title>{{$t('questionsbank.btndelete')}}</template>
    <template #description>{{$t('questionsbank.deletedesc')}}</template>
    <template #content>
      <p>{{$t('questionsbank.questiondelete')}}</p>
    </template>
    <template #footer>
      <button type="button" class="btn btn--tertiary danger" @click.prevent="deleteQuestionById(confirmDeleteQuestionById)" :disabled="submitting">{{$t('questionsbank.confirmdelete')}}</button>
      <button type="button" class="btn btn--primary btn--outline" @click.prevent="confirmDeleteQuestionById = null" :disabled="submitting">{{$t('questionsbank.canceldelete')}}</button>
    </template>
  </modal>
</template>

<script>
import { inject, reactive } from 'vue'
import BaseViewLayout from '@/components/BaseViewLayout'
import QuestionCard from '@/components/QuestionCard'
import Modal from '@/components/Modal'
import questionKinds from '@/utils/questionKinds'

export default {
  name: 'Questions',
  components: { BaseViewLayout, QuestionCard, Modal },
  data () {
    return {
      questionKinds,
      confirmDeleteQuestionById: null,
      submitting: false,
      deletedQuestionIds: []
    }
  },
  computed: {
    questions () {
      return this.myQuestions.filter(q => !this.deletedQuestionIds.includes(q.id))
    },
    isAdmin () {
      return this.$store.state.user.role_id === 1
    }
  },
  methods: {
    deleteQuestionById (id) {
      this.submitting = true
      this.axios.delete(`questions/${id}`).then(() => {
        this.deletedQuestionIds.push(id)
        this.submitting = false
        this.confirmDeleteQuestionById = null
      })
    }
  },
  setup () {
    const axios = inject('$axios')
    const output = {
      myQuestions: reactive([]),
      axios
    }
    axios.get('questions').then(res => res.data.questions.forEach(question => output.myQuestions.push(question)))
    return output
  }
}
</script>
